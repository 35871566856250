import { Avatar, BirthdayDate, Button, config } from '@clout-team/web-components'
import React, { useEffect, useRef, useState } from 'react'
import {
  fillProfileInformation,
  userFalseInviteFillProfile,
  userTrueInviteFillProfile,
} from '../../shared/requests'
import { useAppSelector } from '../../shared/hooks/useAppSelector'
import { readFileAsync, uploadSignupAvatar } from '../../shared/lib/files'
import { useLoginActions } from '../../state/slices/login'
import Heading from '../../shared/ui/Heading/Heading'
import Input from '../../shared/ui/Input/Input'
import './Profile.scss'
import { Cookies } from 'react-cookie'
import MobileHeader from '../../shared/ui/MobileHeader/MobileHeader'
import ButtonUI from '../../shared/ui/ButtonUI/ButtonUI'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../shared/hooks/useAppDispatch'
import { WrapperMobilePage } from '../../shared/ui/WrapperMobilePage/WrapperMobilePage'
import { isInnerIframe } from '../../shared/lib/functions'
import Loader from '../../shared/ui/Loader/Loader'
import classNames from 'classnames'

const cookies = new Cookies()

const Profile: React.FC = () => {
  const { t } = useTranslation()

  const { setAvatarId, setBaseAvatar, setIsLoading, setNameSurname, setStep } =
    useLoginActions()

  const [nameValue, setNameValue] = useState<string>('')
  const [surnameValue, setSurnameValue] = useState<string>('')
  const [date, setDate] = useState<number>(915148800000) // Таймстамп для 01-01-1999
  const avatarB64 = useAppSelector((state) => state.login.avatar_b64)
  const avatarId = useAppSelector((state) => state.login.avatar_id)
  const avatarRef = useRef<null | HTMLInputElement>(null)
  const mail = useAppSelector((state) => state.login.mail)
  const isLoading = useAppSelector((state) => state.login.is_loading)
  const dispatch = useAppDispatch()
  const inviteHash = useAppSelector((state) => state.login.invite_hash)
  const logType = useAppSelector((state) => state.login.type)
  const hashCode = useAppSelector((state) => state.login.hash_code)
  const avatarLink = useAppSelector((state) => state.login.avatar_link)
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false)
  const [firstNameNotValid, setFirstNameNotValid] = useState(false)
  const [lastNameNotValid, setLastNameNotValid] = useState(false)
  const regex = /^[\p{L}\p{N}]+$/u;

  const error = useAppSelector((state) => state.login.error)

  const [stepSettingProfile, setStepSettingProfile] = useState(0)

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()
    setNameValue(val)
    if (val === '' || regex.test(val)) {
      setFirstNameNotValid(false)
    } else {
      setFirstNameNotValid(true)
    }
  }

  const onSurnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim()
    setSurnameValue(val)
    if (val === '' || regex.test(val)) {
      setLastNameNotValid(false)
    } else {
      setLastNameNotValid(true)
    }
  }

  useEffect(() => {
    if (logType === 'INVITE_LINK') {
      dispatch(userFalseInviteFillProfile({hash_code: hashCode, hash: inviteHash}))
    }
  }, [])

  const onContinueHandler = () => {
    setIsLoading(true)
    if (!isDisabled) {
      // const dateValue = new Date(date).toLocaleDateString().replace(/\./g, '-')
      const dateValue = new Date(date)
      const day = String(dateValue.getDate()).padStart(2, '0')
      const month = String(dateValue.getMonth() + 1).padStart(2, '0')
      const year = dateValue.getFullYear()
      const formattedDate = `${day}-${month}-${year}`;
      setNameSurname({ name: nameValue, surname: surnameValue })

      const mainData = {
        name: decodeHtmlEntities(nameValue),
        surname: decodeHtmlEntities(surnameValue),
        avatar_id: avatarId,
        city_id: '',
        birthday: formattedDate,
      }

      debugger

      // if (logType === 'INVITE_LINK' || logType === 'INVITE_DOMAIN') {
        // const dataInvite = {
        //   ...mainData,
        //   hash_code: hashCode,
        //   hash: inviteHash,
        // }
        // dispatch(userFalseInviteFillProfile({ ...dataInvite }))
      if (logType === 'INVITE_MAIL' || logType === 'INVITE_LINK' || logType === 'INVITE_DOMAIN' || logType === 'TYPE_1') {
        const dataInviteMail = {
          ...mainData,
          hash: inviteHash,
        }
        dispatch(userTrueInviteFillProfile({ ...dataInviteMail }))
      } else {
        const data = {
          ...mainData,
          hash: inviteHash,
        }
        dispatch(
          fillProfileInformation({
            ...data,
          }),
        )
      }
    }
  }

  const decodeHtmlEntities = (text: string) => {
    const textArea = document.createElement('textarea')
    textArea.innerHTML = text
    return textArea.value
  }

  const onAvatarChangeHandler = (e: any) => {
    const file = e.target.files[0]
    setIsLoadingAvatar(true)

    readFileAsync(file)
      .then((b64) => {
        if (b64) {
          setBaseAvatar(b64 as string)
          if (avatarLink) {
            uploadSignupAvatar(avatarLink, file)
              .then((res) => {
                dispatch(setAvatarId((res as any)?.id))
              })
              .finally(() => {
                setIsLoadingAvatar(false)
              })
            }
        }
      })
      .catch(() => {
        setIsLoadingAvatar(false)
      })
  }

  const onBackHandler = () => {
    if (logType === 'INVITE_MAIL') {
      if (stepSettingProfile === 1) {
        setStepSettingProfile(0)
      } else {
        setStep('COMPANY_BANNER')
      }
    }
  }

  const onSubmit = () => {
    if (stepSettingProfile === 0 && logType !== 'TYPE_1') {
      if (config.IS_MOBILE) {
        const activeElement = document.activeElement;
        if (activeElement?.tagName === 'INPUT') {
          (activeElement as HTMLInputElement)?.blur()
        }
        if ('virtualKeyboard' in navigator) {
          try {
            // @ts-ignore
            navigator?.virtualKeyboard?.hide?.()
          } catch (e) {
            console.log(e)
          }
        }
      }
      setStepSettingProfile(1)
    } else {
      onContinueHandler()
    }
  }

  const isDisabled = !nameValue || !surnameValue || isLoadingAvatar || firstNameNotValid || lastNameNotValid

  if (config.IS_MOBILE) {
    const onBack = () => {
      if (stepSettingProfile === 1) {
        setStepSettingProfile(0)
      } else {
        setStep('WORKSPACE')
      }
    }

    const isHasBackButton = isInnerIframe() && cookies.get('token')

    return (
      <WrapperMobilePage className={'pr_profile'}>
        <MobileHeader
          title={
            stepSettingProfile === 0
              ? t('profile.name.title')
              : t('profile.date.title')
          }
          onClose={isHasBackButton ? onBack : undefined}
        />

        {stepSettingProfile === 0 ? (
          <div className='pr_profile_form'>
            <div className={'pr_profile__avatar'}>
              <Avatar
                preview={false}
                type='private'
                isFullIconSize
                avatarBg
                upload
                onChange={onAvatarChangeHandler}
                size='56'
                src={avatarB64 === null ? '' : avatarB64}
                inputref={avatarRef}
              />
              <Button
                loading={isLoadingAvatar}
                disabled={isLoadingAvatar}
                onClick={() => (avatarRef.current as any).click()}
                className={'pr_profile__avatar_btn'}
                color={'white'}
              >
                {t('profile.photo')}
              </Button>
            </div>

            <div className='pr_profile_name'>
              <Input
                maxLength={64}
                dismiss={false}
                capitalizeValue={false}
                className={'pr_profile__input'}
                placeholder={t('auth.new.profile.enter_first_name')}
                title={t('auth.new.profile.name')}
                errorMessage={firstNameNotValid ? t('profile.input.valid_error') : undefined}
                value={nameValue}
                focus
                type={'text'}
                // onBlurHandler={onBlurNameHandler}
                onChange={onNameChange}
              />
              <Input
                maxLength={64}
                dismiss={false}
                capitalizeValue={false}
                className={'pr_profile__input'}
                placeholder={t('auth.new.profile.enter_last_name')}
                title={t('auth.new.profile.surname')}
                errorMessage={lastNameNotValid ? t('profile.input.valid_error') : undefined}
                value={surnameValue}
                type={'text'}
                // onBlurHandler={onBlurSurnameHandler}
                onChange={onSurnameChange}
              />
            </div>
          </div>
        ) : (
          <div className='pr_profile_form'>
            <BirthdayDate
              className={'pr_profile__set-birthday'}
              setIsDisableSave={() => {}}
              isBirthday={true}
              useDialog={config.IS_MOBILE}
              onChange={(val) => {
                setDate(val)
              }}
              startDate={new Date('1999-01-01 00:00:00').getTime()}
            />
          </div>
        )}

        <footer className='pr_profile_footer'>
          <ButtonUI
            size={'xlg'}
            loading={isLoading}
            className={'pr_profile__buttons_send'}
            fullWidth
            disabled={isDisabled}
            onClick={onSubmit}
          >
            {stepSettingProfile === 0 ? t('auth.new.main.continue_btn') : t('save')}
          </ButtonUI>
        </footer>
      </WrapperMobilePage>
    )
  }

  return (
    <div className={'pr_profile'}>
      <Heading title={stepSettingProfile === 0 ? t('auth.new.profile.title') : t('profile.date.title')} className='pr_profile_header'/>
      <div className={'pr_profile__wrapper'}>
        <div className={'pr_profile__top'}>
        {stepSettingProfile === 0 ? (
          <>
            <div className={'pr_profile__avatar'}>
              <Avatar
                preview={false}
                type='private'
                isFullIconSize
                avatarBg
                upload
                onChange={onAvatarChangeHandler}
                size='72'
                src={avatarB64 === null ? '' : avatarB64}
                inputref={avatarRef}
              />
              <p
                onClick={() => (avatarRef.current as any).click()}
                className={'pr_profile__avatar_btn'}
              >
                {isLoadingAvatar ? <Loader /> : t('auth.new.profile.add_photo')}
              </p>
            </div>
            <div className={'pr_profile__input_wrapper'}>
              <Input
                dismiss={false}
                capitalizeValue={false}
                className={classNames('pr_profile__input', {'isNotValid': firstNameNotValid})}
                placeholder={t('auth.new.profile.enter_first_name')}
                title={t('auth.new.profile.name')}
                errorMessage={firstNameNotValid ? t('profile.input.valid_error') : undefined}
                value={nameValue}
                focus
                maxLength={64}
                type={'text'}
                onChange={onNameChange}
              />
              <Input
                maxLength={64}
                dismiss={false}
                capitalizeValue={false}
                className={classNames('pr_profile__input', {'isNotValid': lastNameNotValid})}
                placeholder={t('auth.new.profile.enter_last_name')}
                title={t('auth.new.profile.surname')}
                errorMessage={lastNameNotValid ? t('profile.input.valid_error') : undefined}
                value={surnameValue}
                type={'text'}
                onChange={onSurnameChange}
              />
            </div>
          </>
        ) : (
          <div className={'pr_profile__birthday'}>
            <BirthdayDate
              setIsDisableSave={() => {}}
              isBirthday={true}
              useDialog={config.IS_MOBILE}
              onChange={(val) => {
                setDate(val)
              }}
              startDate={new Date('1999-01-01 00:00:00').getTime()}
            />
          </div>
        )}
        </div>
        <div className={'pr_profile__buttons'}>
          {error !== null && <p className={'log_login__error'}>{t(error)}</p>}
          <Button
            size={'lg'}
            // loading={isLoading}
            className={'pr_profile__buttons_send'}
            fullWidth
            disabled={isDisabled}
            onClick={onSubmit}
          >
            {isLoading ? <Loader/> : t('auth.new.main.continue_btn')}
          </Button>
          {logType === 'INVITE_MAIL' && (
            <Button size={'lg'} fullWidth color='white' onClick={onBackHandler}>
              {t('auth.new.profile.back_btn')}
            </Button>
          )}
        </div>
        {logType === 'REG' && (
          <div className='pr_profile_wrapper_description'>
            <p className={'pr_profile__description'}>
              {t('auth.new.profile.description_first')?.replace('{__mail__}', '')}
              <b>{mail}</b>
            </p>
            <p className={'pr_profile__description'}>
              {t('auth.new.profile.description_second')}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Profile
